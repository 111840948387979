
(function ($, window) {
  $.fn.replaceOptions = function (options) {
    var self, $option;

    this.empty();
    self = this;

    $.each(options, function (index, option) {
      $option = $("<option></option>")
        .attr("value", option.id)
        .text(option.name);
      self.append($option);
    });
  };
})(jQuery, window);

window.toggleJuridicalClient = function () {
  if (window.document.getElementById('juridical-toggle').text == 'Soovin arvet ettevõttele') {
    document.getElementById('juridical').value = 'yes';
    window.document.getElementById('juridical-name').style.display = 'block';
    window.document.getElementById('juridical-code').style.display = 'block';
    window.document.getElementById('juridical-vat').style.display = 'block';
    window.document.getElementById('juridical-toggle').text = 'Soovin arvet eraisikule'
  } else {
    document.getElementById('juridical').value = 'no';
    window.document.getElementById('juridical-name').style.display = 'none';
    window.document.getElementById('juridical-code').style.display = 'none';
    window.document.getElementById('juridical-vat').style.display = 'none';
    window.document.getElementById('juridical-toggle').text = 'Soovin arvet ettevõttele'
  };
};

jQuery.ajax({
  url: "/pickups.json",
  type: "GET",
  dataType: "json",
  success: function (data) {
    $("#order_pickup_id").replaceOptions(data);
    $('#order_pickup_id').prop("selectedIndex", 0);
  }
});

window.loadItellaPickups = function () {
  console.log('Itella pickups');
  jQuery.ajax({
    url: "/pickups.json?itella=true", // it should be mapped in routes.rb in rails
    type: "GET", // if you want to send some data.
    dataType: "json",
    success: function (data) {
      console.log(data);
      $("#order_pickup_id").replaceOptions(data);
      $('#order_pickup_id').prop("selectedIndex", 0);
    }
  });
}

window.loadStorePickups = function () {
  console.log('Store pickups');
  jQuery.ajax({
    url: "/pickups.json",
    type: "GET",
    dataType: "json",
    success: function (data) {
      console.log(data);
      $("#order_pickup_id").replaceOptions(data);
      $('#order_pickup_id').prop("selectedIndex", 0);
    }
  });
}